export const prefix = 'https://sdk-source.xylink.com/website/assets/home';
export const mp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/home/b456ec7876112be44741308cac4a8f03950efeda22d12830b7cd4b049c6bf21d.mp4';
export const muteMp4Url = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/3fac15ca1bdf4d4f3d63d796456348e633a906f11ed6646cbe2bd0398ca1b9d2.mp4';
export const mutePoster = 'https://testdevcdn.xylink.com/h5/sdk_doc/static/website/f6934c353137328c363c96e836af8f08ba2c7a608446df33a20fd9f7d40461ed.jpg';

/**
 * 首页banner
 *
 * @param { string } key - 标识
 * @param { string } bgImg - PC背景图
 * @param { string } mBgImg - 移动端背景图
 * @param { string } btnJumpLink - 点击按钮跳转的URL
 * @param { boolean } isTryBtn - 是否显示立即试用按钮
 */
export interface IBannerList {
  key: string;
  content: {
    title: string;
    desc: string;
    mDesc?: string;
    intro?: string;
  };
  btnJumpLink?: string;
  isTryBtn?: boolean;
}

/**
 * 首页banner信息
 */
export const bannerList: IBannerList[] = [
  {
    key: 'newProduct',
    btnJumpLink: '/new_product_2024',
    content: {
      title: '“SVC+AVC”双引擎',
      desc: '引领新一代视频会议',
    },
  },
  {
    key: 'advantage',
    btnJumpLink: '/advantage',
    content: {
      title: '小鱼易连「硬」实力',
      desc: '高安全 · 超融合 · 全连接',
      mDesc: '高安全·超融合·全连接',
      intro: '',
    },
  },
  {
    key: 'try',
    btnJumpLink: 'https://www.xylink.com/news/faa83a',
    // isTryBtn: true,
    content: {
      title: '中国私有云会议',
      desc: '市场占有率第一',
      intro: '数据源于IDC报告《中国云会议市场份额》',
    },
  },
  {
    key: 'whitePaper',
    btnJumpLink: 'https://www.xylink.com/news/946',
    content: {
      title: '重磅发布视频会议行业系列白皮书',
      desc: '云视频关键技术及标准化白皮书/保密视频会议系统通讯协议',
      intro: '数字政府/数字央企/数字银行融合视频系统建设白皮书',
    },
  },
];

/**
 * @param { string } title - 标题
 * @param { string } desc - 描述
 * @param { string } bgImg - PC背景图
 * @param { string } mBgImg - 移动端背景图
 * @param { string } descJumpUrl - 点击描述跳转的URL
 * @param { string } key - 模块名称标识
 */
export interface ICoreIntroductions {
  title: string;
  desc: string;
  descJumpUrl: string;
  key: string;
}

/**
 * 核心介绍模块信息
 */
export const coreIntroductions: ICoreIntroductions[] = [
  { title: '无所不融，「会」无边界', desc: '了解超融合视频平台', descJumpUrl: '/platform', key: 'platform' },
  { title: '品质会议，沉浸体验', desc: '了解全连接视频终端', descJumpUrl: '/hardware', key: 'hardware' },
  { title: '业务集成，大有可为', desc: '了解泛业务开发底座', descJumpUrl: '/sdk_api', key: 'sdk_api' },
];

/**
 * @param { string } title - 标题
 * @param { string } desc - 描述
 * @param { string } smallImg - 未展开前背景图
 * @param { string } openImg - 展开后大背景图
 * @param { string } arrowImg - 背景箭头
 * @param { string } link - 跳转链接
 * @param { string } key - 模块名称标识
 */
export interface ISolution {
  title: string;
  desc: string;
  link: string;
  key: string;
}

/**
 * 行业解决方案模块信息
 */
export const solutions: ISolution[] = [
  {
    title: '政务',
    desc: '赋能数字政府 让政务服务零距离',
    link: '/gov-solution',
    key: 'government',
  },
  {
    title: '政法',
    desc: '助力智慧政法 加速法治现代化建设',
    link: '/court-solution',
    key: 'court',
  },
  {
    title: '企业集团',
    desc: '智能云会议 高效云协同',
    link: '/group-solution',
    key: 'enterprise',
  },
  {
    title: '应急',
    desc: '高效提升应急效率 智慧护航社会安全',
    link: '/er-solution',
    key: 'emergency',
  },
  {
    title: '水利',
    desc: '赋能数字孪生流域 创新推进智慧水利',
    link: '/watercon-solution',
    key: 'waterConservancy',
  },
  {
    title: '金融',
    desc: '以创新科技 助推金融行业数字化转型',
    link: '/finance-solution',
    key: 'finance',
  },
];

/**
 * 地图模块相关客户数字信息
 *
 * @param { string } number - 数字
 * @param { string } text - 文案
 * @param { string } key - 标识
 */
export const mapNumbers: { number: string; text: string; key: string }[] = [
  { number: '10+', text: '省级数字政府视频平台', key: 'governments' },
  { number: '60+', text: '部委和央企客户', key: 'enterprises' },
  { number: '500+', text: '省市/厅局客户', key: 'provincial_customers' },
  { number: '15+', text: '金融监管机构和大型银行', key: 'finance_banks' },
  { number: '17万+', text: '中大型客户', key: 'clients' },
  { number: '8万+', text: '小时稳定运行', key: 'hours' },
];
